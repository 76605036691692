import CardsPNG from '../assets/components/Deposit/Cards.png';
import SbpPNG from '../assets/components/Deposit/SBP.png';

const icons = [
  { name: 'MC/VISA UAH', path: CardsPNG, alt: 'MC/VISA UAH' },
  { name: 'SBP P2P RUB', path: SbpPNG, alt: 'SBP P2P RUB' },
  { name: 'MC/VISA/MIR P2P RUB', path: CardsPNG, alt: 'MC/VISA/MIR P2P RUB' },
];

export const getPaymentIcon = name => {
  const method = icons.find(item => item?.name === name);
  const icon = method && <img src={method?.path} alt={method?.alt} />;
  return icon;
};
