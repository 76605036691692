import './styles/reset.scss';
import './assets/fonts/ProximaNova/stylesheet.css';
import './styles/global.scss';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import i18n from '../i18n';
import { App } from './App';
import { BonusesModalProvider } from './context/BonusesModalContext';
import { GamesModalProvider } from './context/GamesModalContext';
import { ModalProvider } from './context/ModalContext';
import { NotificationsProvider } from './context/NotificationsContext';
import { ThemeProvider } from './context/ThemeContext';
import { UserProvider } from './context/UserContext';
import { KcPage } from './keycloak-theme/kc.gen.tsx';
// Этот блок можно раскомментировать для тестирования страницы Keycloak при разработке
// import { getKcContextMock } from './keycloak-theme/login/KcPageStory.tsx';
//
// if (import.meta.env.DEV) {
//   window.kcContext = getKcContextMock({
//     pageId: 'login.ftl',
//     overrides: {},
//   });
// }

const queryClient = new QueryClient();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  debug: import.meta.env.VITE_SENTRY_debug,
  tracesSampleRate: import.meta.env.VITE_SENTRY_tracesSampleRate || 0,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_replaysOnErrorSampleRate || 0,
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_replaysSessionSampleRate || 0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <>
    {!window.kcContext ? (
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <NotificationsProvider>
            <ThemeProvider>
              <Router>
                <UserProvider>
                  <BonusesModalProvider>
                    <GamesModalProvider>
                      <ModalProvider>
                        <App />
                      </ModalProvider>
                    </GamesModalProvider>
                  </BonusesModalProvider>
                </UserProvider>
              </Router>
            </ThemeProvider>
          </NotificationsProvider>
        </I18nextProvider>
      </QueryClientProvider>
    ) : (
      <KcPage kcContext={window.kcContext} />
    )}
  </>,
);
