import styles from './Recommended.module.css';
import { GameList } from './ui/GameList';

export const Recommended = () => {
  return (
    <div className={styles.container}>
      <h2>Рекомендации</h2>
      <GameList />
    </div>
  );
};
