import Avatar from '../../../../assets/components/Header/Avatar.svg?react';
import styles from './ProfileIcon.module.css';

export const ProfileIcon = ({ user, userLoading, openProfileModal }) => {
  return (
    <button className={styles.wrapper} onClick={() => openProfileModal('profile')}>
      {userLoading ? <p>Loading...</p> : <Avatar />}
    </button>
  );
};
