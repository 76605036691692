import { Bank } from '../components/pages/_modals/Bank/Bank';
import { Bonuses } from '../components/pages/_modals/Bonuses/Bonuses';
import { BonusItemModal } from '../components/pages/_modals/Bonuses/BonusItemModal/BonusItemModal';
import { ChangePhone } from '../components/pages/_modals/ChangePhone/ChangePhone';
import { Deposit } from '../components/pages/_modals/Deposit/Deposit';
import { Profile } from '../components/pages/_modals/Profile/Profile';
import { Settings } from '../components/pages/_modals/Settings/Settings';
import { SmsCodeModal } from '../components/pages/_modals/SmsCodeModal/SmsCodeModal';
import { Transactions } from '../components/pages/_modals/Transactions/Transactions';
import { VerifyEmail } from '../components/pages/_modals/VerifyEmail/VerifyEmail';
import { VerifyPhone } from '../components/pages/_modals/VerifyPhone/VerifyPhone';
import { Withdrawal } from '../components/pages/_modals/Withdrawal/Withdrawal';
import { WithdrawalRequisites } from '../components/pages/_modals/WithdrawalRequisites/WithdrawalRequisites';
import { WithdrawalRequisitesAdd } from '../components/pages/_modals/WithdrawalRequisitesAdd/WithdrawalRequisitesAdd';
import { Loyalty } from '../components/pages/Loyalty/Loyalty';
import { Providers } from '../components/pages/Providers/Providers';

//Надо писать названия в lowercase иначе модалка не понимает
export const modalRoutes = {
  profile: Profile,
  'profile/bonuses': Bonuses,
  'profile/bonuses/bonus': BonusItemModal,
  'profile/loyalty': Loyalty,
  'profile/providers': Providers,
  'profile/transactions': Transactions,
  'profile/settings': Settings,
  deposit: Deposit,
  withdrawal: Withdrawal,
  'withdrawal/requisites': WithdrawalRequisites,
  'withdrawal/requisites/add': WithdrawalRequisitesAdd,
  smscode: SmsCodeModal,
  bank: Bank,
  verifyemail: VerifyEmail,
  changeemail: VerifyEmail,
  verifyphone: VerifyPhone,
  changephone: ChangePhone,
};
