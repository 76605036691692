import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { GamesModalContext } from '../../../../../../context/GamesModalContext';
import { NotificationsContext } from '../../../../../../context/NotificationsContext';
import { GameItem } from '../GameItem/GameItem';

export const ModalContentNovelties = () => {
  const { filteredGames, setFilteredGames } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();

  const getGamesByCategory = useMutation({
    mutationFn: key => postCrmRequest(`/games/games_by_key`, { lang: 'ru', key }),
    onSuccess: data => setFilteredGames(data),
    onError: error => setNotifications({ type: 'error', message: error?.response?.data?.message }),
  });

  const handleClick = key => {
    setFilteredGames([]);
    getGamesByCategory.mutate(key);
  };

  return (
    <>
      {filteredGames?.map(game => (
        <GameItem key={game?.id} game={game} onClick={() => handleClick(game?.key)} />
      ))}
    </>
  );
};
