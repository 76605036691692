import styles from './Wallet.module.css';

export const Wallet = ({ crmUserData, userLoading }) => {
  const balance = crmUserData && crmUserData?.wallets?.code?.credit;

  return (
    <div className={styles.wallet}>
      <div className={styles.icon}>₽</div>
      {userLoading ? <p>Loading...</p> : <div className={styles.balance}>{balance ?? 0}</div>}
    </div>
  );
};
