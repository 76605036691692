import { useNavigate } from 'react-router-dom';

import BTC from '../../../assets/components/Footer/BTC.svg?react';
import InstagramSvg from '../../../assets/components/Footer/InstagramSvg.svg?react';
import P2P from '../../../assets/components/Footer/P2P.svg?react';
import T from '../../../assets/components/Footer/T.svg?react';
import TGChat from '../../../assets/components/Footer/TGChat.svg?react';
import TgSvg from '../../../assets/components/Footer/TgSvg.svg?react';
import VK from '../../../assets/components/Footer/VK.svg?react';
import XSvg from '../../../assets/components/Footer/XSvg.svg?react';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Footer.module.css';

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.footer}>
      <div className={styles.logo}>
        <LogoComponent variant='big' />
      </div>
      <div className={styles.links}>
        <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
          <TgSvg />
        </a>
        <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
          <VK />
        </a>
        <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
          <TGChat />
        </a>
        <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
          <InstagramSvg />
        </a>
        <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
          <XSvg />
        </a>
      </div>
      <div className={styles.refs}>
        <div onClick={() => navigate('/bonusesPage')}>Бонусы</div>
        <div onClick={() => navigate('/loyaltyPage')}>Лояльность</div>
        <div onClick={() => navigate('/providersPage')}>Провайдеры</div>
        <div onClick={() => navigate('/rulesPage')}>Правила</div>
        <div onClick={() => navigate('/FAQPage')}>FAQ</div>
        <div onClick={() => navigate('/')}>Контакты</div>
      </div>
      <div className={styles.techs}>
        <P2P />
        <T />
        <BTC />
        <img src='./Tech4.png' alt='NoData' className={styles.techImg} />
        <img src='./Tech5.png' alt='NoData' className={styles.techImg} />
      </div>
      <div className={styles.copyright}>
        Copyright © <span>******</span> All Reserved
      </div>
    </div>
  );
};
