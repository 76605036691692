import { PageHeader } from '../../molecules/PageHeader/PageHeader';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './BonusesPage.module.css';
import { BonusPageItem } from './ui/BonusPageItem/BonusPageItem';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

export const BonusesPage = () => {
  const {
    userState: { isAuthenticated },
  } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      navigate('/profile/bonuses');
    }
  });
  return (
    <>
      <Header />
      <PageHeader goTo='/' title='Все бонусы' />
      <div className={styles.container}>
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
        <BonusPageItem onClick={handleClick} />
      </div>
      <NavBarBottom />
    </>
  );
};
