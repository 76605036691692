import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { modalRoutes } from '../router/modalRoutes';

const modalNames = {
  '/profile/bonuses': 'Бонусы',
  '/profile/loyalty': 'Лояльность',
  '/profile': 'Профиль',
};

export const useModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalName, setModalName] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (modalNames[location.pathname]) {
      setModalName(prevValue => {
        return { prev: prevValue.current, current: modalNames[location.pathname] };
      });
    }
  }, []);

  const openModal = content => {
    setModalIsOpen(true);
    setModalContent(content);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    navigate('/');
  };

  const handleGoBack = () => {
    setModalName(prevValue => {
      return { current: prevValue.prev };
    });
  };

  const changeModalContent = (newContent, title) => {
    // магия с обновлением стейтов, которая позволяет держать актуальный заголовок модалки. возможно работает некорректно, но свою задачу выполняет.
    // TODO: разобраться позже
    setModalName(prevValue => {
      return { prev: prevValue.current, current: title };
    });
    setModalContent(newContent);
    if (typeof newContent === 'string') navigate(`/${newContent.toLowerCase()}`);
  };

  return {
    modalIsOpen,
    modalContent,
    modalName,
    openModal,
    closeModal,
    handleGoBack,
    setModalName,
    setModalContent,
    changeModalContent,
    setModalIsOpen,
  };
};
