import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginButton from '../../../assets/components/Header/LoginButton.svg?react';
import { UserContext } from '../../../context/UserContext';
import { Button } from '../../atoms/Button/Button';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Header.module.css';
import { ProfileIcon } from './ui/ProfileIcon';
import { Wallet } from './ui/Wallet';

export const Header = ({ openModal }) => {
  const {
    userState: { isAuthenticated, crmUserData },
  } = useContext(UserContext);

  const navigate = useNavigate();
  const theme = localStorage.getItem('theme');

  const clientId = 'Web App Client';
  const scope = 'openid email';
  const authUrl = `${import.meta.env.VITE_KEYCLOAK_URL}/realms/vocxod/protocol/openid-connect/auth?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${import.meta.env.VITE_KEYCLOAK_REDIRECT_URL}&theme=${theme}&kc_locale=ru`;
  const registerUrl = `${import.meta.env.VITE_KEYCLOAK_URL}/realms/vocxod/protocol/openid-connect/registrations?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${import.meta.env.VITE_KEYCLOAK_REDIRECT_URL}&theme=${theme}&kc_locale=ru`;

  const handleLogin = () => {
    window.location.href = authUrl;
  };

  const handleRegister = () => {
    window.location.href = registerUrl;
  };

  return (
    <div className={styles.header}>
      <button className={styles.logo} onClick={() => navigate('/')}>
        <LogoComponent />
      </button>
      {isAuthenticated ? (
        <>
          <Wallet crmUserData={crmUserData} />
          <ProfileIcon user={crmUserData} openProfileModal={openModal} />
        </>
      ) : (
        <>
          <Button className={styles.registerButton} buttonText='Регистрация' onClick={handleRegister} />
          <div className={styles.loginButton} onClick={handleLogin}>
            <LoginButton />
          </div>
        </>
      )}
    </div>
  );
};
