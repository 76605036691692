import DOMPurify from 'dompurify';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { convertDateFormat } from '../../../../utils/convertDateFormat';
import { prepareNewsImageString } from '../../../../utils/prepareNewsImageString';
import styles from '../News.module.css';

export const NewsItem = forwardRef(({ news, newsItem: { local_ru, id, publishedAt } }, ref) => {
  const navigate = useNavigate();
  const handleNewsClick = () => {
    navigate(`/news/${id}`, { state: { news } });
  };

  const img = prepareNewsImageString(local_ru?.short_text, import.meta.env.VITE_STATIC_DOMAIN, local_ru?.alt_desc);

  return (
    <div ref={ref} className={styles.newsItem} onClick={handleNewsClick}>
      <div
        className={styles.newsImage}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(img),
        }}
      />
      <div className={styles.newsPanel}>
        <div className={styles.panelInfo}>
          <p>{local_ru?.title}</p>
          <span className={styles.date}>{convertDateFormat(publishedAt)}</span>
        </div>
      </div>
    </div>
  );
});
