import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationsContext } from '../context/NotificationsContext';

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

export const useCrmCall = () => {
  const { setNotifications } = useContext(NotificationsContext);
  const navigate = useNavigate();

  const getCrmRequest = async url => {
    const token = localStorage.getItem('authToken');
    try {
      const { data } = await apiClient.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data || null;
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 405) {
        setNotifications({ type: 'error', message: 'DEFAULT' });
      } else if ([502, 503, 504].includes(error?.response?.status)) {
        navigate('/technical-works');
      } else {
        Sentry.captureException(error);
        throw error;
      }
    }
  };

  const postCrmRequest = async (url, reqData = {}) => {
    const token = localStorage.getItem('authToken');
    try {
      const { data } = await apiClient.post(url, reqData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      return data || null;
    } catch (error) {
      if (error?.response?.status === 401 || error?.response?.status === 405) {
        setNotifications({ type: 'error', message: 'DEFAULT' });
      } else if ([502, 503, 504].includes(error?.response?.status)) {
        navigate('/technical-works');
      } else {
        Sentry.captureException(error);
        throw error;
      }
    }
  };

  return { getCrmRequest, postCrmRequest };
};
