import { useContext, useEffect, useState } from 'react';

import LogoWhiteBig from '../../../assets/components/Footer/LogoWhiteBig.svg?react';
import LogoBlack from '../../../assets/components/Header/LogoBlack.svg?react';
import LogoWhite from '../../../assets/components/Header/LogoWhite.svg?react';
import { ThemeContext } from '../../../context/ThemeContext';

export const LogoComponent = ({ variant = 'default' }) => {
  const { theme } = useContext(ThemeContext);
  const [systemTheme, setSystemTheme] = useState('light');

  useEffect(() => {
    const systemThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleSystemThemeChange = () => {
      setSystemTheme(systemThemeQuery.matches ? 'dark' : 'light');
    };

    handleSystemThemeChange(); // Инициализация системной темы при монтировании компонента
    systemThemeQuery.addListener(handleSystemThemeChange); // Слушатель для изменений системной темы

    return () => {
      systemThemeQuery.removeListener(handleSystemThemeChange); // Удаление слушателя при размонтировании
    };
  }, []);

  const finalTheme = theme === 'system' ? systemTheme : theme;

  const renderLogo = () => {
    if (finalTheme === 'dark') {
      return variant === 'big' ? <LogoWhiteBig /> : <LogoWhite />;
    }

    return variant === 'big' ? <LogoWhiteBig /> : <LogoBlack />;
  };

  return renderLogo();
};
