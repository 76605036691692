import CatSvg from '../../../../assets/components/NavBar/Cat.svg?react';
import FavSvg from '../../../../assets/components/NavBar/Fav.svg?react';
import NovSvg from '../../../../assets/components/NavBar/Nov.svg?react';
import ProSvg from '../../../../assets/components/NavBar/Pro.svg?react';
import styles from './NavBarHome.module.css';
import { useContext } from 'react';
import { GamesModalContext } from '../../../../context/GamesModalContext';

export const NavBarHome = ({ style }) => {
  const { openModal, modalContent } = useContext(GamesModalContext);

  return (
    <div className={styles.navBarHome} style={style}>
      <button
        onClick={() => openModal('favorite')}
        className={`${styles.navBarHomeItem} ${modalContent === 'favorite' ? styles.active : ''}`}
      >
        <FavSvg />
        <p>Избранные</p>
        {modalContent === 'favorite' && <div className={styles.navBarHomeItemMark} />}
      </button>
      <button
        onClick={() => openModal('category')}
        className={`${styles.navBarHomeItem} ${modalContent === 'category' ? styles.active : ''}`}
      >
        <CatSvg />
        <p>Категории</p>
        {modalContent === 'category' && <div className={styles.navBarHomeItemMark} />}
      </button>
      <button
        onClick={() => openModal('providers')}
        className={`${styles.navBarHomeItem} ${modalContent === 'providers' ? styles.active : ''}`}
      >
        <ProSvg />
        <p>Провайдеры</p>
        {modalContent === 'providers' && <div className={styles.navBarHomeItemMark} />}
      </button>
      <button
        onClick={() => openModal('novelties')}
        className={`${styles.navBarHomeItem} ${modalContent === 'novelties' ? styles.active : ''}`}
      >
        <NovSvg />
        <p>Новинки</p>
        {modalContent === 'novelties' && <div className={styles.navBarHomeItemMark} />}
      </button>
    </div>
  );
};
