import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCrmCall } from '../../../../api/useCrmCall';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import { NumberInput } from '../../../molecules/NumberInput/NumberInput';
import { PaymentMethods } from '../../../organisms/PaymentMethods/PaymentMethods';
import styles from './Deposit.module.css';

export const Deposit = ({ changeModalContent }) => {
  const { setNotifications } = useContext(NotificationsContext);
  const [amount, setAmount] = useState();
  const [method, setMethod] = useState();
  const [error, setError] = useState();
  const { t } = useTranslation('Deposit');
  const { postCrmRequest } = useCrmCall();

  const initDeposit = useMutation({
    mutationFn: data => postCrmRequest('/payment/deposit_init', data),
    onSuccess: data => (document.location.href = data),
    onError: error => {
      setNotifications({ type: 'error', message: error });
      setError(error);
    },
  });

  const handleClick = useCallback(newContent => {
    changeModalContent(newContent);
  }, []);

  const handleAmountChange = useCallback(e => {
    setAmount(e.target.value);
  }, []);

  const depositData = {
    method,
    amount,
    redirectUrl: window?.location?.origin,
  };

  return (
    <div className={styles.deposit}>
      <ContentSwitcher active='Deposit' handleClick={handleClick} />
      <NumberInput labelText={t('labelText')} name='amount' onChange={handleAmountChange} value={amount} />
      <PaymentMethods header={t('header')} onSelect={setMethod} />
      {error && <p className={styles.error}>{error?.message}</p>}
      <Button
        disabled={!amount || !method}
        buttonText={t('buttonText')}
        onClick={() => initDeposit.mutate(depositData)}
      />
    </div>
  );
};
