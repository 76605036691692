import { useCallback, useContext, useEffect, useRef } from 'react';

import { NotificationsContext } from '../../../context/NotificationsContext';
import { useInfiniteNews } from '../../../hooks/useInfiniteNews';
import styles from './News.module.css';
import { NewsItem } from './ui/NewsItem';

export const News = () => {
  const { setNotifications } = useContext(NotificationsContext);
  const { data = [], error, fetchNextPage, hasNextPage, isFetchingNextPage, isInitialLoading } = useInfiniteNews();
  const observer = useRef();

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  const lastNewsElementRef = useCallback(
    node => {
      if (isFetchingNextPage || isInitialLoading) return; // Не вызываем, пока идёт начальная загрузка или запрос новой страницы

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 1 },
      );

      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage, isInitialLoading],
  );

  if (!data) return <p>Loading...</p>;

  return (
    <div className={styles.news}>
      {data?.pages?.map((page, pageIndex) =>
        page?.map((newsItem, newsIndex) => {
          const isLastItem = pageIndex === data.pages?.length - 1 && newsIndex === page?.length - 1;
          return (
            <NewsItem
              key={newsItem?.id}
              news={data?.pages}
              newsItem={newsItem}
              ref={isLastItem ? lastNewsElementRef : null}
            />
          );
        }),
      )}
      {isFetchingNextPage && <p>Loading more...</p>}
    </div>
  );
};
