import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { GamesModalContext } from '../../../context/GamesModalContext';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { UserContext } from '../../../context/UserContext';
import styles from './GamePage.module.css';

export const GamePage = () => {
  const { state } = useLocation();
  const { filteredGames } = useContext(GamesModalContext);
  const { setNotifications } = useContext(NotificationsContext);
  const {
    userState: { user },
  } = useContext(UserContext);
  const { postCrmRequest } = useCrmCall();

  const navigate = useNavigate();

  const game = state?.game ?? filteredGames?.find(item => item?.id === state?.id);

  const userId = user?.userId || null;

  useEffect(() => {
    if (!userId) navigate('/');
  }, [userId]);

  const {
    data: gameUrl = '',
    error,
    isLoading,
  } = useQuery({
    queryKey: ['gameSession', game?.key],
    queryFn: () => postCrmRequest('/games/game_session', { gameKey: game?.key }),
    enabled: Boolean(userId),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  return isLoading ? (
    <p>Loading...</p>
  ) : (
    <iframe
      src={gameUrl}
      title={game?.key}
      width='100%'
      height='100%'
      style={{ 'max-width': 'none', flex: '1' }}
      frameBorder='0'
      allowFullScreen
    />
  );
};
