/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/effect-coverflow';

import * as Accordion from '@radix-ui/react-accordion';
import * as Progress from '@radix-ui/react-progress';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowLeftSvg from '../../../assets/components/Loyalty/ArrowLeftSvg.svg?react';
import { NotificationsContext } from '../../../context/NotificationsContext';
import { Button } from '../../atoms/Button/Button';
import { LoyaltyBadge } from '../../atoms/LoyaltyBadge/LoyaltyBadge';
import { PrivilegeItem } from '../../atoms/privilegeItem/PrivilegeItem';
import styles from './Loyalty.module.scss';

const currentlevel = 4;
const levelWords = ['Зеленый', 'Белый', 'Бронза', 'Серебро', 'Золото', 'Платина'];
const privilegeList = [
  'Бонус на день рождение',
  'Персональный VIP менеджер',
  'Еженедельный кэшбэк 20%',
  'Mercedes E-Class',
  'Личный водитель',
];

export const Loyalty = ({ changeContent }) => {
  const { getCrmRequest } = useCrmCall();
  const [initProgress, setInitProgress] = useState(0);
  const [slider, setSlider] = useState();
  const [activeIndex, setActiveIndex] = useState(currentlevel);
  const { setNotifications } = useContext(NotificationsContext);

  const { data, error } = useQuery({
    queryKey: ['loyalty'],
    queryFn: () => getCrmRequest('/user/loyalty'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }

    setTimeout(() => {
      setInitProgress(65);
    }, 1000);
  }, []);

  const handleClick = newContent => {
    changeContent(newContent);
  };

  return (
    <>
      <div className={styles.backWrapper}>
        <div className={styles.levelWrapper}>
          <LoyaltyBadge level={currentlevel} />
        </div>
        {currentlevel >= 6 ? (
          <div className={styles.progressFinal}>
            <p>Вы достигли максимального уровня лояльности</p>
          </div>
        ) : (
          <div className={styles.progressBox}>
            <div className={styles.progressHeader}>
              <span>{levelWords[currentlevel - 1]}</span>
              <span>{levelWords[currentlevel]}</span>
            </div>
            <Progress.Root className={styles.progressRoot} value={65}>
              <Progress.Indicator
                className={styles.progressIndicator}
                style={{ transform: `translateX(-${100 - initProgress}%)`, transition: '1s' }}
              />
            </Progress.Root>
            <div className={styles.progressFooter}>
              <span>65%</span>
              <span>100%</span>
            </div>
          </div>
        )}
        <div className={styles.mainContent}>
          <div className={styles.sliderWrapper}>
            <div className={styles.arrowBtn} onClick={() => (slider.activeIndex = activeIndex - 1)}>
              <ArrowLeftSvg />
            </div>

            <Swiper
              effect='coverflow'
              spaceBetween={20}
              slidesPerView={3}
              centeredSlides={true}
              modules={[EffectCoverflow]}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 60,
                modifier: 1,
                scale: 0.9,
                slideShadows: false,
              }}
              onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}
              onSwiper={swiper => {
                setSlider(swiper);
                swiper.activeIndex = currentlevel - 1;
                setActiveIndex(currentlevel - 1);
              }}
            >
              {levelWords.map(el => (
                <SwiperSlide className={styles.slide}>{el}</SwiperSlide>
              ))}
            </Swiper>

            <div className={styles.arrowBtn}>
              <div className={clsx(styles.arrowBtn, styles.rotate)}>
                <ArrowLeftSvg />
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.contentHead}>
              <Button buttonText='Приз за уровень' className={styles.btn} />
              <Button buttonText='Еженедльный кэшбэк' className={styles.btn} />
            </div>

            {privilegeList.map((el, i) => (
              <PrivilegeItem text={el} disable={i > activeIndex - 1} />
            ))}
          </div>
        </div>

        <div className={styles.contentFooter}>
          <Accordion.Root className={styles.accordionRoot} type='single' defaultValue='item-1' collapsible>
            <Accordion.Item className={styles.accordionItem} value='item-1'>
              <Accordion.Trigger>Как начисляются очки ?</Accordion.Trigger>
              <Accordion.Content className={styles.accordionContent}>Какой то текст</Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>

          <Accordion.Root className={styles.accordionRoot} type='single' defaultValue='item-2' collapsible>
            <Accordion.Item className={styles.accordionItem} value='item-2'>
              <Accordion.Trigger>Как начисляются очки ?</Accordion.Trigger>
              <Accordion.Content className={styles.accordionContent}>Какой то текст</Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </div>
    </>
  );
};
