import { format, parseISO } from 'date-fns';
import { format as formatTz, toZonedTime } from 'date-fns-tz';

export const convertDateFormat = (dateStr, outputFormat = 'dd/MM/yyyy HH:mm:ss', options = {}) => {
  const { timeZone = 'UTC', withTimeZone = false } = options;

  // Шаг 1: Автоматический парсинг строки даты
  const parsedDate = parseISO(dateStr);

  // Проверка на валидность даты
  if (Number.isNaN(parsedDate.getTime())) {
    // eslint-disable-next-line unicorn/prefer-type-error
    throw new Error('Invalid date string provided');
  }

  // Шаг 2: Переводим дату в указанный часовой пояс, если нужно
  const zonedDate = withTimeZone ? toZonedTime(parsedDate, timeZone) : parsedDate;

  // Шаг 3: Форматируем дату в указанный выходной формат
  return withTimeZone ? formatTz(zonedDate, outputFormat, { timeZone }) : format(zonedDate, outputFormat);
};
