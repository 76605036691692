import { clsx } from 'clsx';
import { memo } from 'react';

import styles from './Button.module.css';

/**
 * Кнопка с различными состояниями (загрузка, успех, отключена) и возможностью кастомизации класса и текста.
 *
 * @param {boolean} [props.disabled=false] Отключает кнопку, если true.
 * @param {string} [props.buttonText=''] Текст, отображаемый на кнопке.
 * @param {string} [props.className=''] Дополнительный класс для стилизации.
 * @param {boolean} [props.isLoading=false] Отображает состояние загрузки, если true.
 * @param {boolean} [props.isSuccess=false] Отображает состояние успеха, если true.
 * @param {Function} props.onClick Обработчик нажатия на кнопку.
 *
 */
export const Button = memo(
  ({ disabled, buttonText = '', className = '', isLoading = false, isSuccess = false, onClick }) => {
    return (
      <button
        className={clsx(styles.button, className, disabled && styles.disabled)}
        onClick={isLoading || isSuccess ? null : onClick}
      >
        {isLoading ? 'Loading...' : isSuccess ? 'OK' : buttonText}
      </button>
    );
  },
);
