import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { verifyEmail } from '../../../../api/post/verifyEmail';
import { UserContext } from '../../../../context/UserContext';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { Button } from '../../../atoms/Button/Button';
import { Input } from '../../../molecules/Input/Input';
import styles from './VerifyEmail.module.css';

export const VerifyEmail = () => {
  const {
    userState: { user },
  } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationsContext);
  const [inputState, setInputState] = useState('');
  const verifyEmailMutation = useMutation({
    mutationFn: data => {
      return verifyEmail(data);
    },
    onError: error => setNotifications({ type: 'error', message: error }),
  });
  const navigate = useNavigate();

  const handleChange = useCallback(e => {
    setInputState(e.target.value);
  }, []);

  const data = {
    id: user?.userId,
    email: inputState,
  };

  return verifyEmailMutation.isSuccess ? (
    <>
      <p className={styles.verifySuccess}>
        Для подтверждения перейдите по ссылке, отправленной на указанную вами почту
      </p>
      <Button buttonText='На главную' onClick={() => navigate('/')} />
    </>
  ) : (
    <>
      <Input name='verifyEmail' labelText='Адрес электронной почты' value={inputState} onChange={handleChange} />
      <Button buttonText='Продолжить' onClick={() => verifyEmailMutation.mutate(data)} />
    </>
  );
};
