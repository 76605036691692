import { useNavigate } from 'react-router-dom';

import gameIconExample from './gameIconExample.png';
import styles from './GameItem.module.css';

export const GameItem = ({ id, game, activeGameId, setActiveGameId }) => {
  const navigate = useNavigate();

  const handleGameClick = () => {
    if (activeGameId === id) {
      navigate(`/game/${game?.key}`, { state: { id, game } });
    } else {
      setActiveGameId(id);
    }
  };
  return (
    <div
      onClick={handleGameClick}
      className={styles.gameIco}
      style={{ transform: activeGameId === id ? 'scale(1.05)' : 'scale(1)' }}
    >
      <img src={gameIconExample} alt={game?.name} />
    </div>
  );
};
