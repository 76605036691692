import { useNavigate } from 'react-router-dom';

import styles from './GameItem.module.css';

export const GameItem = ({ id, game, activeGameId, setActiveGameId }) => {
  const navigate = useNavigate();
  const imgSource = game?.main_image || game?.img;
  const img = imgSource?.replace(/__domain__/g, import.meta.env.VITE_STATIC_DOMAIN);
  const gameKey = game?.key || game?.url_key;

  const handleGameClick = () => {
    if (activeGameId === id) {
      navigate(`/game/${gameKey}`, { state: { id } });
    } else {
      setActiveGameId(id);
    }
  };

  return (
    <div
      onClick={handleGameClick}
      className={styles.gameIco}
      style={{ transform: activeGameId === id ? 'scale(1.05)' : 'scale(1)' }}
    >
      <img src={img} alt={game?.name} />
    </div>
  );
};
