import axios from 'axios';

export const getNews = async ({ pageParam = 1 }) => {
  try {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/news`, {
      params: { page: pageParam, perPage: 2 },
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data?.message;
  }
};
