import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';

import { useCrmCall } from '../../../../api/useCrmCall';
import { NotificationsContext } from '../../../../context/NotificationsContext';
import { GameItem } from './GameItem';
import styles from './GameList.module.css';

export const GameList = () => {
  const [activeGameId, setActiveGameId] = useState(null);
  const { setNotifications } = useContext(NotificationsContext);
  const { postCrmRequest } = useCrmCall();

  const { data: games, error } = useQuery({
    queryKey: ['recommendedGames'],
    queryFn: () =>
      postCrmRequest('/games/recommended_games', {
        lang: 'ru',
      }),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      setNotifications({ type: 'error', message: error?.response?.data?.message });
    }
  }, [error]);

  return (
    <div className={styles.gameList}>
      {games?.map(game => (
        <GameItem
          key={game?.id}
          id={game?.id}
          game={game}
          activeGameId={activeGameId}
          setActiveGameId={setActiveGameId}
        />
      ))}
    </div>
  );
};
