import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../api/useCrmCall';
import { NotificationsContext } from './NotificationsContext';

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const { setNotifications } = useContext(NotificationsContext);
  const [modalState, setModalState] = useState({});
  const [pageFrom, setPageFrom] = useState('');
  const { postCrmRequest } = useCrmCall();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const addRequisiteMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_add_requisite', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userData'] });
      navigate('/withdrawal/requisites');
    },
    onError: error => setNotifications({ type: 'error', message: error }),
  });

  const mutationsMap = {
    withdrawal: addRequisiteMutation,
  };

  return (
    <ModalContext.Provider
      value={{ modalState, setModalState, mutationRequest: mutationsMap[pageFrom], pageFrom, setPageFrom }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
